import React from 'react';
import './Certificate.css';
import Certimage from '../../components/certimage/Certimage';
import Python from '../../assets/certPython.jpg'

const Certificate = () => {
  return (
    <div className='webapp__certificate section__padding'>
      <div className='webapp__certificate-title'>
        <h1>Certificates</h1>
      </div>
      <div className='webapp__certificate-image'>
        <img src={Python} alt='Python-certificate'/>
        <img src={'https://udemy-certificate.s3.amazonaws.com/image/UC-c848fa52-d3fe-4fe0-80ec-06845ebeb3e1.jpg'} alt='wb-development-certificate'/>
        

        
      </div>
    
    </div>
  )
}

export default Certificate