


const skills_front = [
  {
    id: 1,
    title: 'HTML'
  },
  {
    id: 2,
    title: 'CSS'
  },
  {
    id: 3,
    title: 'JavaScript',
  },
  {
    id: 4,
    title: 'React'
  },
  {
    id: 5,
    title: 'Next.js'
  },
  {
    id: 6,
    title: 'Bootstrap'
  },
  {
    id:7,
    title: 'jQuery'
  },
  {
    id: 8, 
    title: 'Flask'
  },
  {
    id: 9, 
    title: 'Django'
  },
  {
    id: 10, 
    title: 'Tkinter'
  }
];

const skills_back= [
  {
    id: 10,
    title: 'Python'
  },
  {
    id: 11,
    title: "NodeJS",
  },
  {
    id: 12,
    title: 'Express'
  },
  {
    id: 13,
    title: 'PostgreSQL'
  },
  {
    id: 14,
    title: 'MongoDB'
  },
];

const skills_tools = [
    {
        id: 15,
        title: 'Git'
      },
      {
        id: 16,
        title: 'GitHub'
      },
      {
        id: 17,
        title: 'VsCode'
      },
      {
        id: 18,
        title: 'PyCharm'
      }
    
];

const about_me = [
  {
    aboutMeText: `Hey, I'm Cisco, a self-taught programmer with a background in hospitality management and scuba diving instruction. Coding started as a means to bring my own projects to life, but it quickly became my true passion. With nearly two decades of experience in hospitality and diving, I bring valuable skills like attention to detail, adaptability, and exceptional customer service to the tech industry.`

  },
  {
    aboutMeText: `Through self-study and hands-on projects, I've built a solid foundation in Python, JavaScript, and React. As a quick learner and dedicated team player, I'm eager to take on new challenges and responsibilities as a junior developer.`
  },
  {
    aboutMeText: `What sets me apart is my diverse perspective and worldly experiences. Living in different countries has given me a deep appreciation for diversity and fresh insights. If my blend of skills and enthusiasm intrigues you, let's connect and embark on an exciting journey together in creating innovative solutions to real-world challenges. Reach out to me today!`
  }
];



export default { skills_front, skills_back, skills_tools, about_me };
