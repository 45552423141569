import React from 'react';
import { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';


import './Navbar.css'
import Button from '../button/Button';
import { mylogo } from '../../assets/import';

const Navbar = () => {

  const Menu = () => (
    <>
        <p><a href='#about'>About Me</a></p>
        <p><a href='#skills'>Skills</a></p>
        <p><a href='#projects'>Projects</a></p>
          
    </>
  )


  const [toggleMenu, setToggleMenu]=useState(false);
  return (
    <div className='webapp__navbar'>
      <div className='webapp__navbar-links'>
        <div className='webapp__navbar-links-logo'>
        <a href='https://ciscodev.website/' aria-label='home_logo'>

          <img src={mylogo} alt='logo'/>
        </a>  
        </div>
        <div className='webapp__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='webapp__navbar-btn'>
        <Button />
       
      </div>
      <div className='webapp__navbar-menu'>
        {toggleMenu
        ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line  color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (<div className='webapp__navbar-menu_container scale-up-center'>
          <div className='webapp__navbar-menu_container-links'>
            <Menu />
            <div className='webapp__navbar-menu_container-links-sign'>
             
              <Button />
              {/* <button type='button'>Contact Me</button> */}
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default Navbar