import React from 'react';
import './Project.css';
import ProjectCard from '../../components/projectcard/ProjectCard';
// import caculator_react from '../../assets/caculator_react.png';

import { imgInventory, imgquiz, imgtodo, 
  imgdiving, imgEcommerce, imgChainLink } from '../../assets/import';

const Project = () => {

  const techChainLink = ['React.js','react-router-dom','TypeScript'
  ,'Rainbowkit','MongoDB', 'Node.js', 'Trello'];
  const techInventory = ['Python','Django', 'Bootstrap', 'Jinja2'];
  const techQuizApp = ['JavaScript'];
  const techTodoList = ['React.js','React-icons', 'uuid'];
  const techDiving = ['React.js','react-responsive-carousel' ]
  const techInEcommerce = ['Django','Python', 'Jinja2','Tailwind' ]

  return (
    <div className='webapp__project section__padding' id='projects'>
      <div className='webapp__project-title'>
        <h1>Project</h1>
      </div>
      <div className='webapp__project-content'>
         {/* ChainLink Hackathon */}
         <div className='webapp__project-content_component'>
          <ProjectCard 
            image = {imgChainLink}
            title= "Alianza"
            project_type= "Chainlink Hackathon"
            text_description= {`
            Alianza's frontend developer, I enhanced React.js skills in a global hackathon, 
            mastering Git and collaborating on a groundbreaking blockchain project. Alianza 
            leverages Chainlink, Polygon, Avalanche, and MongoDB, enabling users to mint 
            Alliance NFTs for active civic participation. This experience deepened my 
            understanding of blockchain, citizen engagement, and accountability, fueling my 
            enthusiasm for impactful projects.`}
            // technames
            tech_names = {techChainLink}
            online={'https://allianz-teal.vercel.app/'}
            sourcecode={'https://github.com/leetebbs/Alianza'}

        />
        </div>
        {/* web app */}
        <div className='webapp__project-content_component'>
          <ProjectCard 
            image = {imgdiving}
            title= "Diving Pinoy (home page)"
            project_type= "React"
            text_description="React-based landing page for a diving web app, fulfilling a long-time dream of bringing my projects to life through development. This idea of creating a web app for diving in Boracay has been in my mind for years, starting with a guide on paper. Now, I'm taking it a step further by building this platform to provide diving information and facilitate bookings for both beginners and enthusiasts."
            tech_names = {techDiving}
            // online={''}
            sourcecode={'https://github.com/CiscoMerchan/Inventory_with_django/tree/main'}

        />
        </div>
        {/* todo list */}
        <div className='webapp__project-content_component'>
          <ProjectCard 
            image = {imgtodo}
            title= "To do List"
            project_type= "React"
            text_description="Through this web app project, I gained practical knowledge in utilizing props and the useState hook to manage data flow between components. The implementation of event listeners, such as onChange and onSubmit, ensured seamless data updates and efficient form handling in React. This experience significantly enhanced my understanding of data management within the app development process."
            // technames
            tech_names = {techTodoList}
            online={''}
            sourcecode={'https://github.com/CiscoMerchan/Todo_List__with_Reactjs/tree/main'}

        />
        </div>
        {/* Quiz app */}
        <div className='webapp__project-content_component'>
          <ProjectCard
            image = {imgquiz}
            title= "Quiz App"
            project_type= "Vanilla JavaScript"
            text_description="The Quiz App is a JavaScript-based 
            web app designed for quiz enthusiasts. It fetches trivia 
            questions and answers from an external API, validates 
            user-selected answers, and incorporates interactive 
            features like button listeners, audio feedback, and score 
            management. This project aimed to enhance JavaScript 
            proficiency, explore external APIs, and refine app 
            functionality, workflow, and lifecycle comprehension."
            tech_names = {techQuizApp}
            online={'https://ciscomerchan.github.io/Joel-Quiz_app'}
            sourcecode={'https://github.com/CiscoMerchan/Joel-Quiz_app'}
          />
        </div>
        {/* inventory */}
        <div className='webapp__project-content_component'>
          <ProjectCard
            image = {imgInventory}
            title= "Inventory"
            project_type= "Django"
            text_description="The Inventory Web Application is a Python and Django-based tool for managing inventory. It allows users to create, edit, and delete suppliers, products, purchase orders, clients, and client orders. Initially developed as a desktop app, it was later recreated as a web app to enhance my understanding of Django. I'm satisfied with the project's outcome and gained valuable insights."
            tech_names = {techInventory}
            // online={''}
            sourcecode={'https://github.com/CiscoMerchan/Inventory_with_django/tree/main'}
          />
        </div>
        {/* E-commerce */}
        <div className='webapp__project-content_component'>
          <ProjectCard
            image = {imgEcommerce}
            title= "E-commerce Web app"
            project_type= "Django"
            text_description="In building this E-commerce web app, I immersed myself in the core concepts of Django, including models, URL paths, views functions, templates, and image storage, along with user authentication and forms. Through practice and diligent reading of Django documentation, I successfully crafted a fully functional web app. Users can effortlessly explore products by category, leave comments, and communicate via messaging. As I continuously work on this project, I aim to provide an enriched shopping experience."
            tech_names = {techInEcommerce}
            // online={''}
            sourcecode={'https://github.com/CiscoMerchan/E-Commerce_app_Django'}
          />
        </div>

        {/* TEST */}
        {/* <div className='webapp__project-content_component'>
          <ProjectCard 
            image = {jJ}
            title= "Calculator"
            project_type= "React"
            text_description="That's pretty obvuios, because there are no refs in my child code about the parent component itself (they get composited in another component ad-hoc). So is there a way to have this feature back? Because is very usefull to me."
            // techname
            // tech_name= 'react'
            online={'https://www.billboard.com/charts/hot-100/1979-10-13/'}
            sourcecode={'https://www.billboard.com/charts/hot-100/1979-10-13/'}

        />
        </div>
        <div className='webapp__project-content_component'>
          <ProjectCard
            image = {jJ}
            title= "Calculator"
            project_type= "React"
            text_description="That's pretty obvuios, because there are no refs in my child code about the parent component itself (they get composited in another component ad-hoc). So is there a way to have this feature back? Because is very usefull to me."
            // technames
            online={'https://www.billboard.com/charts/hot-100/1979-10-13/'}
            sourcecode={'https://www.billboard.com/charts/hot-100/1979-10-13/'}
          />
        </div>
        <div className='webapp__project-content_component'>
          <ProjectCard
            image = {jJ}
            title= "Calculator"
            project_type= "React"
            text_description="That's pretty obvuios, because there are no refs in my child code about the parent component itself (they get composited in another component ad-hoc). So is there a way to have this feature back? Because is very usefull to me."
            // technames
            online={'https://www.billboard.com/charts/hot-100/1979-10-13/'}
            sourcecode={'https://www.billboard.com/charts/hot-100/1979-10-13/'}
          />
        </div> */}
        
        
        
      </div>
    </div>
  )
}

export default Project