
import './App.css';
import { ScrollTopArrow } from './components';

import Navbar from './components/navbar/Navbar';
import { About, Certificate, Contact, Footer, Header, Project, Skill} from './containers'

function App() {
  return (
    <div className="App">
    <Navbar />  
    <ScrollTopArrow />
    <Header />
    <About />
    <Skill />
    <div className='certificate__section'>
    <Certificate />
    </div>
    <Project />
    <Contact />
    <Footer />
    </div>
  );
}

export default App;
