import React from 'react'
import CiscoPic from '../../assets/CiscoPic.jpg';
import './Header.css';
import Button from '../../components/button/Button';
const Header = () => {
  return (
    <div className='webapp__header  section__padding'  id='home'>
    <div className='webapp__header-container'>
      <h1 className='gradient__text'>Hi there, I'm <strong>Cisco</strong></h1>
      <p>I'm a Web Developer based in Maroochydore, Queensland.</p>
      <Button />

      
    </div>
    <div className='webapp__header-image'>
      <img src={CiscoPic} alt='main img'/>
    </div>
    
  </div>
  )
}

export default Header