import React from 'react';
import './ProjectCard.css'

const Technologie = ({ tech_names }) => {
    return(
        <>
            <span className="tech_name">{tech_names}</span>
        </>
    )
}




const ProjectCard = (props) => {

    const techNames = props.tech_names || [];
    const hasOnlineLink = !!props.online; // Check if props.online exists

  return (
    <div className='webapp__pjtcard'>
        <div className='webapp__pjtcard-image'>
        <img src={props.image}/>
        </div>
        <div className='webapp__pjtcard-content'>
            <div className='webapp__pjtcard-content_text'>
                <h2>{props.title}</h2>
                <h3>{props.project_type}</h3>
                <p>{props.text_description}</p>
            </div>

            <div className='webapp__pjtcard-content_tech'>
                <h3>Technologies:
                    {techNames.map((tech_name, index) => (
                        <Technologie key={index} tech_names={tech_name} />
                    ))}
                </h3>
                    {/* <span>
                    <Technologie tech_name={props.tech_name} />
                    </span>  */}
            
            </div>
            
            <div className='webapp__pjtcard-content_button'>
                {hasOnlineLink && ( // Conditionally render the <a> tag
                <a href={props.online} target="_blank" rel="noreferrer" aria-label="">
                Live Demo
                </a>
                )}
                <a href={props.sourcecode} target="_blank" rel="noreferrer" aria-label="">Source Code</a>

            </div>

        </div>

    </div>
  )
}

export default ProjectCard