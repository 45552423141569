import React from 'react'
import './Button.css'


const Button = () => {
  return (
    <div className='webapp__btn'>
        <a href='mailto: franciscoeliasm@yahoo.com'>Contact Me</a>
    </div>
  )
}

export default Button