import React from 'react'
import './Contact.css'
import ContactCard from '../../components/contactcard/ContactCard';
import data from '../../constants';

const Contact = () => {
  return (
    <div className='webapp__contact section__padding'>
      <div className='webapp__contact-title'>
        <h1>Contact</h1>
      </div>

      <div className='webapp__contact-content'>
        <div className='webapp__contact-card'>
          <ContactCard 
          link='mailto: franciscoeliasm@yahoo.com'
          
          image={'fa-solid fa-envelope'}
          title='Email' 
          text="Let's get in touch." 
          />
        </div>
        <div className='webapp__contact-card'>
          <ContactCard 
          link={'https://www.linkedin.com/in/ciscomerchan/'}
          
          image={'fa-brands fa-linkedin '}
          title='LinkedIn' 
          text="Let's connect." 
          />
        </div>
        <div className='webapp__contact-card'>
          <ContactCard 
          link={'https://github.com/CiscoMerchan'}
          
          image={'fa-brands fa-github '}
          title='GitHub' 
          text="Check out my repos." 
          />
        </div>

      </div>
    </div>
  )
}

export default Contact

// <i class="fa-solid fa-envelope text-blue-700 text-3xl "></i>
// <i class="fa-brands fa-linkedin text-blue-700 text-3xl "></i>
// <i class="fa-brands fa-github text-blue-700 text-3xl "></i>