import React from 'react';
import data from '../../constants';
import { BsCodeSlash } from 'react-icons/bs';
import { ImHtmlFive } from 'react-icons/im';
import { FaTools } from 'react-icons/fa';
import { VscServerProcess } from "react-icons/vsc";
import './Skill.css';
import SkillCard from '../../components/skillcard/SkillCard';

const Skill = () => {
  return (
    <div className='webapp__skills section__padding' id='skills'>
      <div className='webapp__skills-title'>
        <h1>Skills</h1>      
      </div>
      <div className='webapp__skills-content'>
      <div className='webapp__skills-icons'>
        <BsCodeSlash  style={{ fontSize: '50px'}} />
        <div className='webapp__skills-line' />
        {data.skills_front.map((skill) => 
          <SkillCard key={skill.id} title={skill.title}/>
        )}
        
      </div>
     
      <div className='webapp__skills-icons'>
      {/* <ImHtmlFive  style={{ fontSize: '50px'}} /> */}
      <VscServerProcess style={{ fontSize: '50px'}} />
      <div className='webapp__skills-line' />
        {data.skills_back.map((skill) => 
          <SkillCard key={skill.id} title={skill.title}/>
        )}
      </div>
        
      <div className='webapp__skills-icons'>
      <FaTools  style={{ fontSize: '50px'}} /> 
      <div className='webapp__skills-line' /> 
        {data.skills_tools.map((skill) => 
          <SkillCard key={skill.id} title={skill.title}/>
        )}
      </div>
      </div>
     
       
    </div>
  )
}

export default Skill

console.log(data);