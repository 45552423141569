import React from "react";
import './About.css'
import data from '../../constants';
import { imgjiu_jitsu, imghospitality,imgunderwater, imgdivingAbout } from "../../assets/import";
const About = () => {
  return (
    <div className="webapp__about section__padding" id='about'>
      <div className='title'>
        <h1 className='header__title'>About Me</h1>
      </div>
      <div className='webapp__about-content'>
        <div className='webapp__about-content_img'>
          <img src={imgdivingAbout} alt="aboutme_diving"/>
          <img src={imghospitality} alt="aboutme_hospitality"/>
          <img src={imgjiu_jitsu} alt="aboutme_jiu-jitsu"/>
          <img src={imgunderwater} alt="aboutme_underwarter"/>

        </div>
        <div className='webapp__about-content_aboutme'>
          {data.about_me.map((text, index) => 
            <p key={index}><span style={{marginLeft: '1.5rem'}}/>{text.aboutMeText}</p>
          )}
          
        </div>

      </div>
      
    </div>
  )
}

export default About