import React from 'react'
import './ContactCard.css'
const ContactCard = (props) => {
  return (
    <div className='webapp__card'>
      <a href={props.link} target='_blank' rel="noopener noreferrer"> 
        <div className='webapp__card-contact'>
            
            <i className={props.image}></i>
            
            <p>{props.title}</p>
        </div>
        <h3>{props.text}</h3>
      </a>  
    </div>
  )
}

export default ContactCard