import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className='webapp__footer'>
      <h4>© Copyright 2023 CiscoDev. All rights reserved.</h4>

    </div>
  )
}

export default Footer